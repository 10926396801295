import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Footer from './components/footer/Footer'
import Navbar from './components/navbar/Navbar'
import Home from './pages/home/Home'
import Management from './pages/management/Management'
import ManagementById from './pages/management-by-id/ManagementById'
import News from './pages/news/News'
import NewsById from './pages/news-by-id/NewsById'
import axios from 'axios'
import ContactPage from './pages/contact-page/ContactPage'


// LOCAL
// export const BASE_URL = 'http://localhost:4005/api'

// SERVER
// export const BASE_URL = 'http://192.168.145.23:4005'
export const BASE_URL = 'https://hudud.karantin.uz/api'


axios.defaults.baseURL = BASE_URL

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/news' element={<News />} />
        <Route path='/news/:id' element={<NewsById />} />
        <Route path='/management' element={<Management />} />
        <Route path='/management/:id' element={<ManagementById />} />
        <Route path='/contact' element={<ContactPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App