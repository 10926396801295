import React from 'react'
import './contactPage.css'
import Contact from '../contact/Contact'

const ContactPage = () => {
    return (
        <div className='main-contact'>
            <Contact />
        </div>
    )
}

export default ContactPage