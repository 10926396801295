import React from 'react'
import { FaPodcast, FaUsers } from 'react-icons/fa'
import { CgSupport } from 'react-icons/cg'
import './activityDirection.css'
import { useTranslation } from 'react-i18next'

const ActivityDirection = () => {

    const { t } = useTranslation()

    return (
        <div className='activity-direction-page'>
            <h1 className='header1'>{t('activity.lines')}</h1>
            <div className='wrapper'>
                <a href='https://pm.gov.uz/uz#/' target="_blank" rel="noreferrer">
                    <div className='one'>
                        <div className='bg'>
                            <FaPodcast className='icon' />
                        </div>
                        <h3>{t('activity.mur')}</h3>
                    </div>
                </a>
                <a href='https://efito.uz/check-certificate/' target="_blank" rel="noreferrer">
                    <div className='one'>
                        <div className='bg'>
                            <FaPodcast className='icon' />
                        </div>
                        <h3>eFito.uz</h3>
                    </div>
                </a>
                <a href='https://assalomagro.uz/login' target="_blank" rel="noreferrer">
                    <div className='one'>
                        <div className='bg'>
                            <CgSupport className='icon' />
                        </div>
                        <h3>Assalomagro.uz</h3>
                    </div>
                </a>
                <a href='https://efito.uz/invoice/?code=d8960582-8342-4d9d-b9b3-5601393e3f92&state=add_invoice' target="_blank" rel="noreferrer">
                    <div className='one'>
                        <div className='bg'>
                            <FaUsers className='icon' />
                        </div>
                        <h3>Oferta</h3>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default ActivityDirection