import React from 'react'
import './partners.css'
import { useTranslation } from 'react-i18next'

const Partners = () => {

    const { t } = useTranslation()

    return (
        <div className='partners-page'>
            <h1 className='header1'>{t('partners.partners')}</h1>
            <div className='wrapper'>
                <a href='http://singlewindow.uz/?lang=uz_UZ' target="_blank" rel="noreferrer">
                    <div className='one1'></div>
                </a>
                <a href='https://id.egov.uz/oz' target="_blank" rel="noreferrer">
                    <div className='one2'></div>
                </a>
            </div>
        </div>
    )
}

export default Partners