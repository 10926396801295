import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import './newsById.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import { dateFormat } from '../../utils/dateFormat'
import { BASE_URL } from '../../App'
import Loader from '../../components/loader/Loader'
import { useSelector } from 'react-redux'

const NewsById = () => {

    const { lang } = useSelector(state => state.nowLanguage)

    const params = useParams()
    const id = params.id

    const [loading, setLoading] = useState(true)
    const [news, setNews] = useState({})

    const updateViews = () => {
        axios.put(`/v1/news/views/${id}`, {})
            .then(res => {
            }).catch(err => {
                console.log(err)
            })
    }

    const getNewsById = () => {
        axios.get(`/v1/news/${id}`)
            .then(res => {
                setNews(res.data.news)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getNewsById()
        updateViews()
        // eslint-disable-next-line
    }, [])

    return (
        <div className='news-by-id-page'>
            {
                loading ? <Loader /> : (
                    <>
                        <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${news?.photo})` }}></div>
                        <h1>{news?.title ? news?.title[lang] : null}</h1>
                        <div>{ReactHtmlParser(news?.description ? news?.description[lang] : null)}</div>
                        <div className='d-flex justify-content-between'>
                            <div></div>
                            <div className='btm'>
                                <span>{dateFormat(news?.createdAt)}</span>
                                <AiOutlineEye className='icon' />
                                <span>{news?.views}</span>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default NewsById