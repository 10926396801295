import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./homeSwiper.css";
import { Pagination, Navigation } from "swiper";
import axios from 'axios';
import Loader from '../../components/loader/Loader';
import { BASE_URL } from '../../App';

const HomeSwiper = () => {

    const [loading, setLoading] = useState([])
    const [allBanners, setAllBanners] = useState([])

    const getAllBanners = (page) => {
        setLoading(true)
        axios.get(`/v1/banners`)
            .then(res => {
                setAllBanners(res.data.banners)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAllBanners()
    }, [])


    return (
        <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
        >
            {
                loading ? <Loader /> : null
            }
            {
                allBanners?.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className='one-home-banner' style={{ backgroundImage: `url(${BASE_URL}/${item.photo})` }}></div>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    )
}

export default HomeSwiper