import cookie from 'js-cookie';
import { LANG } from '../types';

const initialLang = cookie.get('i18next') || 'uz';

const initilState = {
    lang: initialLang
}

export const languageReducers = (state = initilState, action) => {
    switch (action.type) {
        case LANG:
            return {
                lang: action.payload
            }

        default:
            return state
    }
}
