import React, { useEffect, useState } from 'react'
import timePng from '../../assets/icons/time.png'
import emailPng from '../../assets/icons/email.png'
import phonePng from '../../assets/icons/phone.png'
import addressPng from '../../assets/icons/address.png'
import ReactInputMask from 'react-input-mask'
import './contact.css'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Modal } from 'antd'

const Contact = () => {

    const { t } = useTranslation()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [addressLink, setAddressLink] = useState('')
    const [phoneTrust, setPhoneTrust] = useState('')
    const [phoneCallCenter, setPhoneCallCenter] = useState('')

    const getDetails = () => {
        axios.get('/v1/details')
            .then(res => {
                if (res.data.details[0]) {
                    setEmail(res.data.details[0].email)
                    setAddress(res.data.details[0].addressText.uz)
                    setAddressLink(res.data.details[0].addressLink)
                    setPhoneTrust(res.data.details[0].phoneTrust)
                    setPhoneCallCenter(res.data.details[0].phoneCallCenter)
                }
            }).catch(err => {
                console.log(err)
            })
    }

    const [fullname, setFullname] = useState('')
    const [sendEmail, setSendEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [topic, setTopic] = useState('')
    const [text, setText] = useState('')
    const submitForm = (e) => {
        e.preventDefault()

        setLoader(true)
        axios.post('/v1/messages', {
            fullname,
            email: sendEmail,
            phone,
            topic,
            text
        }).then(res => {
            setIsModalOpen(true)
            setTimeout(() => {
                setIsModalOpen(false)
            }, 3000);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoader(false)
        })
    }

    useEffect(() => {
        getDetails()
    }, [])

    return (
        <div className='contact-page'>
            <Modal
                title={null}
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                centered
                className='one-modal'
            >
                <h1>So'rovingiz muvaffaqqiyatli yuborildi</h1>
            </Modal>
            <h1 className='header1'>{t('contact.contact')}</h1>
            <div className='wrapper'>
                <form onSubmit={submitForm}>
                    <h2>{t('contact.msg')}</h2>
                    <div>
                        <input type='text' placeholder='F.I.SH' value={fullname} onChange={(e) => setFullname(e.target.value)} required />
                        <input type='email' placeholder='Email' value={sendEmail} onChange={(e) => setSendEmail(e.target.value)} />
                    </div>
                    <div>
                        <ReactInputMask mask="+\9\9\8 (99) 999 99 99" type='text' placeholder='+998' value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        <input type='text' placeholder='Xabar mavzusi' value={topic} onChange={(e) => setTopic(e.target.value)} />
                    </div>
                    <textarea placeholder='Sizning xabaringiz' value={text} onChange={(e) => setText(e.target.value)} required></textarea>
                    <button type='submit' disabled={loader}>
                        <div style={{ display: loader ? 'block' : 'none' }} className='spinner-border m-auto'></div>
                        <span style={{ display: !loader ? 'block' : 'none' }}>{t('contact.send')}</span>
                    </button>
                </form>

                <div className='right'>
                    <h2>{t('contact.aloqa')}</h2>
                    <p>{t('contact.text')}</p>
                    <div className='qator'>
                        <div className='one'>
                            <div className='bg' style={{ backgroundImage: `url(${timePng})` }}></div>
                            <div>
                                <p><strong>{t('contact.work')}</strong></p>
                                <p>{t('contact.time')}</p>
                                <p>09:00 - 18:00</p>
                            </div>
                        </div>
                        <div className='one'>
                            <div className='bg' style={{ backgroundImage: `url(${emailPng})` }}></div>
                            <div>
                                <p><strong>Email</strong></p>
                                <p>{email}</p>
                            </div>
                        </div>
                    </div>
                    <div className='qator'>
                        <div className='one'>
                            <div className='bg' style={{ backgroundImage: `url(${phonePng})` }}></div>
                            <div>
                                <p><strong>{t('contact.tel')}</strong></p>
                                <p>{phoneTrust}</p>
                                <p>{phoneCallCenter}</p>
                            </div>
                        </div>
                        <div className='one'>
                            <div className='bg' style={{ backgroundImage: `url(${addressPng})` }}></div>
                            <div>
                                <p><strong>{t('contact.addres')}</strong></p>
                                <p>{address}</p>
                            </div>
                        </div>
                    </div>
                    <iframe title='Map' src={addressLink} allowFullScreen={true}></iframe>
                </div>
            </div>
        </div>
    )
}

export default Contact