import React from 'react'
import './home.css'
import HomeSwiper from '../home-swiper/HomeSwiper'
import HomeNews from '../home-news/HomeNews'
import ActivityDirection from '../activity-direction/ActivityDirection'
import Partners from '../partners/Partners'
import Appendix from '../appendix/Appendix'
import Services from '../services/Services'
import Contact from '../contact/Contact'

const Home = () => {
    return (
        <div className='home-page'>
            <HomeSwiper />
            <HomeNews />
            <ActivityDirection />
            <Partners />
            {/* <Indicator /> */}
            <Appendix />
            <Services />
            <Contact />
        </div>
    )
}

export default Home