import React, { useEffect, useState } from 'react'
import logoPng from '../../assets/images/logo.png'
import { AiOutlineEye, AiOutlineSearch } from 'react-icons/ai'
import { MdKeyboardArrowDown } from 'react-icons/md'
import './navbar.css'
import { Link } from 'react-router-dom'
import { FiMenu } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { language } from '../../redux/actions/lanAction'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import MobileNavbar from './MobileNavbar'

const Navbar = () => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const dispatch = useDispatch()
    const changeLan = (lang) => {
        setLan(lang)
        i18next.changeLanguage(lang)
        dispatch(language(lang))
    }

    const [open, setOpen] = useState(false)
    const [lan, setLan] = useState(Cookies.get('i18next') || 'uz')

    const [phoneTrust, setPhoneTrust] = useState('')
    const [phoneAntiCorrupt, setPhoneAntiCorrupt] = useState('')
    const [phoneCallCenter, setPhoneCallCenter] = useState('')
    const [boshqarma, setBoshqarma] = useState('')

    const getDetails = () => {
        axios.get('/v1/details')
            .then(res => {
                if (res.data.details[0]) {
                    setPhoneTrust(res.data.details[0].phoneTrust)
                    setPhoneAntiCorrupt(res.data.details[0].phoneAntiCorrupt)
                    setPhoneCallCenter(res.data.details[0].phoneCallCenter)
                    setBoshqarma(res.data.details[0].boshqarma[lang])
                }
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getDetails()
        // eslint-disable-next-line
    }, [lang])

    return (
        <div className='navbar-component'>
            <div className='top'>
                <div className='left'>
                    <Link to='/'>
                        <div className='logo-wrap'>
                            <img src={logoPng} alt='' />
                            <h2>{boshqarma}</h2>
                        </div>
                    </Link>
                    <div className='langs-wrap'>
                        <div className={lan === 'uz' ? 'active-lang' : ''} onClick={() => changeLan('uz')}>O'z</div>
                        <div className={lan === 'ru' ? 'active-lang' : ''} onClick={() => changeLan('ru')}>Ру</div>
                        <div className={lan === 'en' ? 'active-lang' : ''} onClick={() => changeLan('en')}>En</div>
                    </div>
                    <FiMenu className='menu-icon' onClick={() => setOpen(!open)} />
                </div>
                <div className='right'>
                    <AiOutlineEye className='icon-eye' />
                    <div className='search-wrap'>
                        <input type='text' placeholder={t('navbar.search')} />
                        <AiOutlineSearch className='icon' />
                    </div>
                    <div className='p-wrap'>
                        {phoneTrust ? <p>{t('navbar.phone1')}</p> : null}
                        <p><a href={`tel:${phoneTrust}`} className='nav-link'>{phoneTrust}</a></p>
                        {phoneAntiCorrupt ? <p>{t('navbar.phone2')}</p> : null}
                        <p><a href={`tel:${phoneAntiCorrupt}`} className='nav-link'>{phoneAntiCorrupt}</a></p>
                        {phoneCallCenter ? <p>{t('navbar.phone3')}</p> : null}
                        <p><a href={`tel:${phoneCallCenter}`} className='nav-link'>{phoneCallCenter}</a></p>
                    </div>
                </div>
            </div>

            <div className='sticky-navbar'>
                <div className='bottom'>
                    <div className='dropdown-links-wrapper'>
                        <Link to=''>
                            <div className='dropdown-primary-link'>{t('navbar.home')}</div>
                        </Link>
                    </div>

                    <div className='dropdown-links-wrapper'>
                        <Link to='/management'>
                            <div className='dropdown-primary-link'>{t('navbar.boshqarma')} <MdKeyboardArrowDown className='icon' /></div>
                        </Link>
                        <div className='dropdown-links'>
                            <Link to=''>
                                <div className='dropdown-link-item'>{t('navbar.about_boshqarma')}</div>
                            </Link>
                            <Link to='management'>
                                <div className='dropdown-link-item'>{t('navbar.rahbariyat')}</div>
                            </Link>
                            <Link to=''>
                                <div className='dropdown-link-item'>{t('navbar.bulimlar')}</div>
                            </Link>
                            <a href='http://karantin.uz/uz/vacancy' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.vacancy')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/yuqori-turuvchi-tashkilot' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.above')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/-osimliklar-karantini-sohasida-qollaniladigan-atamalar-lugati' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.atamalar')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/sayyor-qabullar' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.qabullar')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/ochiq-malumotlar-' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.open_data')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/korib-chiqilgan-murojaatlar-boyicha-statistik-malumotlar' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.statistics')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/manaviyat-marifat-bolimi' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.manaviyat')}</div>
                            </a>
                            <Link to=''>
                                <div className='dropdown-link-item'>{t('navbar.youth')}</div>
                            </Link>
                            <a href='http://karantin.uz/uz/menu/byudjet-jarayonining-ochiqligi-' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.budget')}</div>
                            </a>
                        </div>
                    </div>

                    <div className='dropdown-links-wrapper'>
                        <Link to=''>
                            <div className='dropdown-primary-link'>{t('navbar.faoliyat')} <MdKeyboardArrowDown className='icon' /></div>
                        </Link>
                        <div className='dropdown-links'>
                            <a href='https://my.gov.uz/oz' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.e_gov')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/davlat-xizmatlari-reestri' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.reestr')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/xalqaro-aloqalar' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.xalqaro')}</div>
                            </a>
                            <Link to=''>
                                <div className='dropdown-link-item'>{t('navbar.dastur_gov')}</div>
                            </Link>
                            <a href='http://karantin.uz/uz/menu/osimliklar-klinikalari1' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.clinics')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/idoralararo-bayonnomalar' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.bayonnoma')}</div>
                            </a>
                            <Link to=''>
                                <div className='dropdown-link-item'>{t('navbar.anti_korrup')}</div>
                            </Link>
                        </div>
                    </div>

                    <div className='dropdown-links-wrapper'>
                        <Link to=''>
                            <div className='dropdown-primary-link'>{t('navbar.axborot')} <MdKeyboardArrowDown className='icon' /></div>
                        </Link>
                        <div className='dropdown-links'>
                            <Link to='/news'>
                                <div className='dropdown-link-item'>{t('navbar.news')}</div>
                            </Link>
                            <a href='http://karantin.uz/uz/tenders' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.xarid_gov')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/tabriklar' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.tabriklar')}</div>
                            </a>
                            <Link to=''>
                                <div className='dropdown-link-item'>{t('navbar.video')}</div>
                            </Link>
                            <Link to=''>
                                <div className='dropdown-link-item'>{t('navbar.fotogalery')}</div>
                            </Link>
                            <Link to=''>
                                <div className='dropdown-link-item'>{t('navbar.bayonnoma')}</div>
                            </Link>
                            <a href='http://karantin.uz/uz/menu/infografika' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.infograf')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/biohi' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.biochem')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/oav' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.oav')}</div>
                            </a>
                        </div>
                    </div>

                    <div className='dropdown-links-wrapper'>
                        <Link to=''>
                            <div className='dropdown-primary-link'>{t('navbar.tadbirkor')} <MdKeyboardArrowDown className='icon' /></div>
                        </Link>
                        <div className='dropdown-links'>
                            <a href='http://karantin.uz/uz/menu/tifth-kodlar' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.tifth')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/qishloq-xojaligi-maxsulotlarini-ekologik-toza-feromon-tutqichlar-orqali-himoyalash-usuli' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.feromen')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/ozbekiston-respublikasidan-koreya-davlatiga-gilos-eksportining-talablari' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.korea_gilos')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/rossiya-federatsiyasi-hududidagi-yashil-yolaklar-royxati' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.russian')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/fitosanitar-toza-issiqxonalar' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.fitosanitar')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/meva-va-sabzavot-yetishtiruvchi-tadbirkorlik-subyektlari' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.meva_subjects')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/paxta-galla-va-moyli-ekin-yetishtiruvchi-klaster-va-fermer-dexqon' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.paxta_ekin')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/biofabrika-va-biolaboratoriyalar' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.biofabrika')}</div>
                            </a>
                            <a href='http://karantin.uz/uz/menu/osimliklar-klinikasi' target='_blank' rel="noreferrer">
                                <div className='dropdown-link-item'>{t('navbar.clinic')}</div>
                            </a>
                        </div>
                    </div>

                    <div className='dropdown-links-wrapper'>
                        <a href='https://karantin.uz/uz/agrokomak' target='_blank' rel="noreferrer">
                            <div className='dropdown-primary-link'>{t('navbar.agrohelp')}</div>
                        </a>
                    </div>

                    <div className='dropdown-links-wrapper'>
                        <Link to='/contact'>
                            <div className='dropdown-primary-link'>{t('navbar.contact')}</div>
                        </Link>
                    </div>
                </div>
            </div>

            <MobileNavbar
                open={open}
                setOpen={setOpen}
            />
        </div>
    )
}

export default Navbar