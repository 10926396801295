import React from 'react'
import servicePng from '../../assets/images/service.png'
import service2Png from '../../assets/images/service2.jpeg'
import service3Png from '../../assets/images/service3.jpeg'
import './services.css'
import { useTranslation } from 'react-i18next'

const Services = () => {

    const { t } = useTranslation()

    return (
        <div className='services-page'>
            <h1 className='header1'>{t('services.services')}</h1>
            <div className='wrapper'>
                <div className='one'>
                    <div className='bg' style={{ backgroundImage: `${servicePng}` }}></div>
                    <h2>{t('services.list')}</h2>
                    <p>{t('services.text1')}</p>
                    <a href='http://karantin.uz/uz/menu/karantin-organizmlari-royxati' target='_blank' rel="noreferrer">
                        <button>{t('services.more')}</button>
                    </a>
                </div>
                <div className='one'>
                    <div className='bg' style={{ backgroundImage: `${service2Png}` }}></div>
                    <h2>{t('services.fito')}</h2>
                    <p>{t('services.text2')}</p>
                    <a href='http://karantin.uz/uz/menu/0028' target='_blank' rel="noreferrer">
                        <button>{t('services.more')}</button>
                    </a>
                </div>
                <div className='one'>
                    <div className='bg' style={{ backgroundImage: `${service3Png}` }}></div>
                    <h2>{t('services.codes')}</h2>
                    <p>{t('services.text3')}</p>
                    <a href='http://karantin.uz/uz/menu/tifth-kodlar' target='_blank' rel="noreferrer">
                        <button>{t('services.more')}</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Services