import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './management.css'
import axios from 'axios'
import { BASE_URL } from '../../App'
import { useSelector } from 'react-redux'

const Management = () => {

    const { lang } = useSelector(state => state.nowLanguage)

    const [allManagements, setAllManagements] = useState([])

    const getAllManagement = () => {
        axios.get(`/v1/management`)
            .then(res => {
                setAllManagements(res.data.managements)
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getAllManagement()
    }, [])

    return (
        <div className='management-component'>
            <h1 className='header1'>Rahbariyat</h1>
            <div className='roow'>
                {
                    allManagements?.map((item, index) => {
                        return (
                            <Link to={item._id} key={index}>
                                <div className='one'>
                                    <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${item.photo})` }}></div>
                                    <h2>{item.fullname[lang]}</h2>
                                    <h3>{item.role[lang]}</h3>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Management