import React, { useEffect, useState } from 'react'
import logoPng from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
import { TbBrandTelegram } from 'react-icons/tb'
import { FaFacebookF } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'
import { AiFillYoutube } from 'react-icons/ai'
import './footer.css'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useSelector } from 'react-redux'

const Footer = () => {

    const { lang } = useSelector(state => state.nowLanguage)

    const { t } = useTranslation()

    const [phoneTrust, setPhoneTrust] = useState('')
    const [email, setEmail] = useState('')
    const [boshqarma, setBoshqarma] = useState('')
    const [address, setAddress] = useState('')
    const [telegram, setTelegram] = useState('')
    const [instagram, setInstagram] = useState('')
    const [facebook, setFacebook] = useState('')
    const [youtube, setYoutube] = useState('')

    const getDetails = () => {
        axios.get('/v1/details')
            .then(res => {
                if (res.data.details[0]) {
                    setPhoneTrust(res.data.details[0].phoneTrust)
                    setEmail(res.data.details[0].email)
                    setAddress(res.data.details[0].addressText[lang])
                    setBoshqarma(res.data.details[0].boshqarma[lang])
                    setTelegram(res.data.details[0].telegram)
                    setInstagram(res.data.details[0].instagram)
                    setFacebook(res.data.details[0].facebook)
                    setYoutube(res.data.details[0].youtube)
                }
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getDetails()
        // eslint-disable-next-line
    }, [lang])

    return (
        <div className='footer-component'>
            <div className='top'>
                <Link to='/'>
                    <div className='logo-wrap'>
                        <img src={logoPng} alt='' />
                        <h2>{boshqarma}</h2>
                    </div>
                </Link>
                <div>
                    <p>{t('contact.addres')}: <span>{address}</span></p>
                    <p>{t('contact.work')}: <span>{t('contact.time')} 09:00 - 18:00</span></p>
                    <a href='https://t.me/uzdavkarantinuz_antikor_bot' target='_blank' rel="noreferrer"><p>{t('navbar.phone2')} <span>@uzdavkarantin_antikor_bot</span></p></a>
                </div>
                <div>
                    {phoneTrust ? <a href={`tel:${email}`} target='_blank' rel="noreferrer"><p>Telefon: <span>{phoneTrust}</span></p></a> : null}
                    {email ? <a href={`mailto:${email}`} target='_blank' rel="noreferrer"><p>E-mail: <span>{email}</span></p></a> : null}
                    <p>
                        {t('contact.social')}
                        {
                            telegram ? (
                                <a href={telegram} target='_blank' rel="noreferrer">
                                    <TbBrandTelegram className='icon' />
                                </a>
                            ) : null
                        }
                        {
                            facebook ? (
                                <a href={facebook} target='_blank' rel="noreferrer">
                                    <FaFacebookF className='icon' />
                                </a>
                            ) : null
                        }
                        {
                            instagram ? (
                                <a href={instagram} target='_blank' rel="noreferrer">
                                    <BsInstagram className='icon' />
                                </a>
                            ) : null
                        }
                        {
                            youtube ? (
                                <a href={youtube} target='_blank' rel="noreferrer">
                                    <AiFillYoutube className='icon' />
                                </a>
                            ) : null
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer