import React, { useEffect, useState } from 'react'
import './homeNews.css'
import com from '../../assets/icons/com.png'
import def from '../../assets/icons/def.png'
import user from '../../assets/icons/user.png'
import map from '../../assets/icons/map.png'
import { AiOutlineEye } from 'react-icons/ai'
import axios from 'axios'
import { dateFormat } from '../../utils/dateFormat'
import { BASE_URL } from '../../App'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const HomeNews = () => {

    const { lang } = useSelector(state => state.nowLanguage)

    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [threeNews, setThreeNews] = useState([])
    const [lastNews, setLastNews] = useState({})

    const getAllNews = (page) => {
        setLoading(true)
        axios.get(`/v1/news/last-four`)
            .then(res => {
                setThreeNews(res.data.news.slice(1, 4))
                setLastNews(res.data.news[0])
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAllNews()
    }, [])

    return (
        <>
            <h1 className='header1'>{t('news.news')}</h1>
            <div className='home-news-page'>
                <div className='left'>

                    {loading ? <Loader /> : null}

                    {/* primary news */}
                    {
                        lastNews ? (
                            <Link to={`/news/${lastNews?._id}`}>
                                <div className='primary-news'>
                                    <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${lastNews?.photo})` }}></div>
                                    <div className='datas'>
                                        <h4>{lastNews?.title ? lastNews?.title[lang] : null}</h4>
                                        <div>{ReactHtmlParser(`${lastNews?.description ? lastNews?.description[lang].slice(0, 800) : null} ...`)}</div>
                                        <div className='btm'>
                                            <span>{dateFormat(lastNews?.createdAt)}</span>
                                            <AiOutlineEye className='icon' />
                                            <span>{lastNews?.views}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ) : null
                    }

                    {/* other news */}
                    <div className='other-news'>
                        {
                            threeNews?.map((item, index) => {
                                return (
                                    <div className='one' key={index}>
                                        <Link to={`/news/${item._id}`}>
                                            <h4>{item.title[lang]}</h4>
                                            <div className='btm'>
                                                <span>{dateFormat(item.createdAt)}</span>
                                                <AiOutlineEye className='icon' />
                                                <span>{item.views}</span>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='right'>
                    <div>
                        <a href='https://pm.gov.uz/ru#/' target='_blank' rel="noreferrer">
                            <div className='qator'>
                                <img src={com} alt='' />
                                <h6>{t('news.qabulxona')}</h6>
                            </div>
                        </a>
                        <a href='https://efito.uz/check-certificate/' target='_blank' rel="noreferrer">
                            <div className='qator'>
                                <img src={def} alt='' />
                                <h6>{t('news.certificate')}</h6>
                            </div>
                        </a>
                        <a href='https://cabinet.karantin.uz/' target='_blank' rel="noreferrer">
                            <div className='qator'>
                                <img src={user} alt='' />
                                <h6>{t('news.cabinet')}</h6>
                            </div>
                        </a>
                        <a href='https://karantin.uz/uz/symbols' target='_blank' rel="noreferrer">
                            <div className='qator'>
                                <img src={map} alt='' />
                                <h6>{t('news.gov')}</h6>
                            </div>
                        </a>
                    </div>
                    <a href='https://karantin.uz/uz/banner/2023-yil-insonga-etibor-va-sifatli-talim-yili' target='_blank' rel="noreferrer">
                        <div className='pres-bg'></div>
                    </a>
                </div>
            </div>
        </>
    )
}

export default HomeNews