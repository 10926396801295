import React, { useEffect, useState } from 'react'
import './managementById.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../App'
import ReactHtmlParser from 'react-html-parser'
import { useSelector } from 'react-redux'

const ManagementById = () => {

    const { lang } = useSelector(state => state.nowLanguage)

    const params = useParams()
    const id = params.id

    const [managementById, setManagementById] = useState({})

    const getManagementById = () => {
        axios.get(`/v1/management/${id}`)
            .then(res => {
                setManagementById(res.data.management)
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getManagementById()
        // eslint-disable-next-line
    }, [])

    return (
        <div className='management-by-id-page'>
            <div className='left'>
                <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${managementById?.photo})` }}></div>
                <h2>{managementById?.role ? managementById?.role[lang] : null}</h2>
                <h3>{managementById?.fullname ? managementById?.fullname[lang] : null} haqida to‘liq ma’lumot</h3>
                <div>{ReactHtmlParser(managementById?.description ? managementById?.description[lang] : null)}</div>
            </div>
        </div>
    )
}

export default ManagementById