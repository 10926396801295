import React from 'react'
import app1 from '../../assets/images/app1.png'
import app2 from '../../assets/images/app2.png'
import app3 from '../../assets/images/app3.png'
import a1 from '../../assets/images/a1.png'
import a2 from '../../assets/images/a2.png'
import './appendix.css'
import { useTranslation } from 'react-i18next'

const Appendix = () => {

    const { t } = useTranslation()

    return (
        <div className='appendix-page'>
            <h1 className='header1'>{t('appendix.ilova')}</h1>
            <div className='wrapper'>
                <img src={app1} alt='' className='img1' />
                <img src={app2} alt='' />
                <img src={app3} alt='' />
            </div>
            <h4>{t('appendix.download')}</h4>
            <div className='roow'>
                <a href="https://play.google.com/store/apps/details?id=uz.karantin.agrokomakchi" target='_blank' rel="noreferrer">
                    <div className='one'>
                        <img src={a1} alt='' />
                        <div>
                            <p>Get It on</p>
                            <h5>Google Play</h5>
                        </div>
                    </div>
                </a>
                <a href="https://apps.apple.com/uz/app/agrokomakchi/id1658946098" target='_blank' rel="noreferrer">
                    <div className='one'>
                        <img src={a2} alt='' />
                        <div>
                            <p>Available on the</p>
                            <h5>App Store</h5>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Appendix