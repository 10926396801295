import React from 'react'
import './loader.css'

const Loader = () => {
    return (
        <div className='loader-component'>
            <div className="loadingio-spinner-rolling-iq9sdmeaqpb"><div className="ldio-ycpqmfwc53k">
                <div></div>
            </div></div>
        </div>
    )
}

export default Loader