import React from 'react'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { Link } from 'react-router-dom'

const MobileNavbar = (props) => {

    const { open, setOpen } = props
    const { t } = useTranslation()

    return (
        <div className={open ? 'mobile-navbar mobile-navbar-active' : 'mobile-navbar'}>
            <div className='d-flex justify-content-between mb-2'>
                <div></div>
                <MdClose className='icon' onClick={() => setOpen(!open)} />
            </div>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Link to='/' onClick={() => setOpen(false)}>{t('navbar.home')}</Link>
                    </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('navbar.boshqarma')}</Accordion.Header>
                    <Accordion.Body>
                        <Link to='' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.about_boshqarma')}</div>
                        </Link>
                        <Link to='management' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.rahbariyat')}</div>
                        </Link>
                        <Link to='' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.bulimlar')}</div>
                        </Link>
                        <a href='http://karantin.uz/uz/vacancy' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.vacancy')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/yuqori-turuvchi-tashkilot' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.above')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/-osimliklar-karantini-sohasida-qollaniladigan-atamalar-lugati' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.atamalar')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/sayyor-qabullar' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.qabullar')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/ochiq-malumotlar-' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.open_data')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/korib-chiqilgan-murojaatlar-boyicha-statistik-malumotlar' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.statistics')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/manaviyat-marifat-bolimi' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.manaviyat')}</div>
                        </a>
                        <Link to='' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.youth')}</div>
                        </Link>
                        <a href='http://karantin.uz/uz/menu/byudjet-jarayonining-ochiqligi-' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.budget')}</div>
                        </a>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='2'>
                    <Accordion.Header>{t('navbar.faoliyat')}</Accordion.Header>
                    <Accordion.Body>
                        <a href='https://my.gov.uz/oz' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.e_gov')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/davlat-xizmatlari-reestri' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.reestr')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/xalqaro-aloqalar' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.xalqaro')}</div>
                        </a>
                        <Link to='' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.dastur_gov')}</div>
                        </Link>
                        <a href='http://karantin.uz/uz/menu/osimliklar-klinikalari1' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.clinics')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/idoralararo-bayonnomalar' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.bayonnoma')}</div>
                        </a>
                        <Link to='' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.anti_korrup')}</div>
                        </Link>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='3'>
                    <Accordion.Header>{t('navbar.axborot')}</Accordion.Header>
                    <Accordion.Body>
                        <Link to='/news' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.news')}</div>
                        </Link>
                        <a href='http://karantin.uz/uz/tenders' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.xarid_gov')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/tabriklar' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.tabriklar')}</div>
                        </a>
                        <Link to='' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.video')}</div>
                        </Link>
                        <Link to='' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.fotogalery')}</div>
                        </Link>
                        <Link to='' onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.bayonnoma')}</div>
                        </Link>
                        <a href='http://karantin.uz/uz/menu/infografika' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.infograf')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/biohi' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.biochem')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/oav' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.oav')}</div>
                        </a>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='4'>
                    <Accordion.Header>{t('navbar.tadbirkor')}</Accordion.Header>
                    <Accordion.Body>
                        <a href='http://karantin.uz/uz/menu/tifth-kodlar' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.tifth')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/qishloq-xojaligi-maxsulotlarini-ekologik-toza-feromon-tutqichlar-orqali-himoyalash-usuli' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.feromen')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/ozbekiston-respublikasidan-koreya-davlatiga-gilos-eksportining-talablari' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.korea_gilos')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/rossiya-federatsiyasi-hududidagi-yashil-yolaklar-royxati' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.russian')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/fitosanitar-toza-issiqxonalar' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.fitosanitar')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/meva-va-sabzavot-yetishtiruvchi-tadbirkorlik-subyektlari' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.meva_subjects')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/paxta-galla-va-moyli-ekin-yetishtiruvchi-klaster-va-fermer-dexqon' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.paxta_ekin')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/biofabrika-va-biolaboratoriyalar' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.biofabrika')}</div>
                        </a>
                        <a href='http://karantin.uz/uz/menu/osimliklar-klinikasi' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-link-item'>{t('navbar.clinic')}</div>
                        </a>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='5'>
                    <Accordion.Header>
                        <a href='https://karantin.uz/uz/agrokomak' target='_blank' rel="noreferrer" onClick={() => setOpen(false)}>
                            <div className='dropdown-primary-link'>{t('navbar.agrohelp')}</div>
                        </a>
                    </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey='6'>
                    <Accordion.Header>
                        <Link to='/contact' onClick={() => setOpen(false)}>
                            <div className='dropdown-primary-link'>{t('navbar.contact')}</div>
                        </Link>
                    </Accordion.Header>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default MobileNavbar