import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import axios from 'axios'
import './news.css'
import Loader from '../../components/loader/Loader'
import { Pagination } from 'antd'
import { BASE_URL } from '../../App'
import { dateFormat } from '../../utils/dateFormat'
import { useSelector } from 'react-redux'

const News = () => {

    const { lang } = useSelector(state => state.nowLanguage)

    const [loading, setLoading] = useState(false)
    const [size, setSize] = useState(1)
    const [allNews, setAllNews] = useState([])
    const getAllNews = (page) => {
        setAllNews([])
        setLoading(true)
        axios.get(`/v1/news?page=${page}`)
            .then(res => {
                setAllNews(res.data.news)
                setSize(res.data.size)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAllNews()
    }, [])

    return (
        <div className='news-page'>
            {
                loading ? <Loader /> : null
            }
            <div className='news-wrapper'>
                {
                    Array.isArray(allNews) && allNews?.length > 0 ? allNews.map((item, index) => {
                        return (
                            <Link to={item._id} key={index}>
                                <div className='one'>
                                    <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${item.photo})` }}></div>
                                    <h1>{item?.title ? item?.title[lang] : null}</h1>
                                    <div className='btm'>
                                        <span>{dateFormat(item.createdAt)}</span>
                                        <AiOutlineEye className='icon' />
                                        <span>{item.views}</span>
                                    </div>
                                </div>
                            </Link>
                        )
                    }) : null
                }
            </div>
            <Pagination
                defaultCurrent={1}
                total={size}
                pageSize={12}
                className='my-5 mx-auto'
                onChange={(page) => {
                    getAllNews(page)
                }}
            />
        </div>
    )
}

export default News